<template>
  <section class="product-add-wrapper">
    <b-row class="product-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="12" md="12">
        <validation-observer ref="refFormObserver">
          <b-form @submit.prevent="validationForm">
            <b-card class="product-preview-card">
              <!-- Invoice Client & Payment Details -->
              <b-card-body class="product-padding pt-0">
                <b-row class="product-spacing">
                  <!-- Col: Invoice To -->
                  <b-col cols="12" xl="6" class="mb-lg-1">
                    <h6 class="mb-2">المنتج :</h6>
                    <validation-provider #default="{ errors }" name="المنتج" rules="required">
                      <b-form-group label="المنتج" label-for="variation_id">
                        <!-- Select Client -->
                        <v-select
                          v-model="productData.variation_id"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'rtl'"
                          :options="mainProducts"
                          :load="mainProducts.length > 0 ? false : true"
                          :reduce="label => label.value"
                          :clearable="false"
                          @input="val => updateItemForm(val)"
                        >
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                    <!-- <v-select
                            v-model="item.itemTitle"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="itemsOptions"
                            label="itemTitle"
                            :clearable="false"
                            class="mb-2 item-selector-title"
                            placeholder="Select Item"
                            @input="(val) => updateItemForm(index, val)"
                          /> -->
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Items Section -->
              <b-card-body class="product-padding form-item-section">
                <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                  <b-row class="pb-2">
                    <b-col ref="row" cols="6" v-for="(item, index) in productData.items" :key="index">
                      <!-- <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1"> 
                          <b-col cols="12" lg="3"> من </b-col>
                          <b-col cols="12" lg="3"> الى </b-col>
                          <b-col cols="12" lg="3"> السعر </b-col>
                          <b-col cols="12" lg="2"> الحالة </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div> -->

                      <div class="d-flex border rounded mb-1">
                        <div>
                          <b-row class="flex-grow-1 pl-1 pr-1 pb-0">
                            <!-- Single Item Form Headers -->
                            <b-col cols="12" lg="12">
                              <label class="w-100 text-center" align="center"> <strong>الكمية</strong> </label>
                            </b-col>
                            <b-col cols="12" lg="6">
                              <validation-provider
                                name="الكمية  "
                                :vid="`items[${index}].from_qty`"
                                :rules="{
                                  required: true,
                                  min_value: 1,
                                  numeric: true,
                                  regex: /^([0-9]+)$/
                                }"
                              >
                                <b-form-group slot-scope="{ valid, errors }" label=" من">
                                  <b-form-input
                                    v-model="item.from_qty"
                                    type="number"
                                    :state="errors.length > 0 ? false : null"
                                    class="mb-2"
                                  />

                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                            <b-col cols="12" lg="6">
                              <validation-provider
                                name="الكمية  "
                                :vid="`items[${index}].to_qty`"
                                :rules="{
                                  required: true,
                                  min_value: 1,
                                  numeric: true,
                                  regex: /^([0-9]+)$/
                                }"
                              >
                                <b-form-group slot-scope="{ valid, errors }" label=" الى">
                                  <!-- <label class="d-inline">الى</label> -->
                                  <b-form-input
                                    v-model="item.to_qty"
                                    type="number"
                                    no-wheel
                                    :class="{ 'is-invalid': !!errors.length }"
                                    :state="errors[0] ? false : valid ? true : null"
                                    class="mb-2"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                          <b-row class="flex-grow-1 pl-1 pr-1">
                            <b-col cols="12" lg="8">
                              <validation-provider
                                #default="{ errors }"
                                name="السعر"
                                :vid="`items[${index}].price`"
                                :rules="{
                                  required: true,
                                  min_value: 3,
                                  regex: /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/
                                }"
                              >
                                <b-form-group label=" السعر">
                                  <!-- <label class="d-inline">السعر</label> -->
                                  <b-form-input
                                    v-model="item.price"
                                    type="number"
                                    :state="errors.length > 0 ? false : null"
                                    class="mb-2"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>

                            <b-col cols="12" lg="4">
                              <validation-provider
                                #default="{ errors }"
                                name="الحالة"
                                :vid="`items[${index}].status`"
                                rules="required"
                              >
                                <b-form-group label=" الحالة">
                                  <!-- <label class="d-inline" :for="`${index}status`">الحالة</label> -->
                                  <b-form-checkbox
                                    :id="`${index}status`"
                                    :checked="item.status"
                                    :state="errors.length > 0 ? false : null"
                                    switch
                                  />

                                  <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                              </validation-provider>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewItemInItemForm">
                  اضافة جديد
                </b-button>
              </b-card-body>
            </b-card>

            <b-card>
              <b-row class="product-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="4" class="mb-lg-1 m-auto">
                  <!-- Button: Send Invoice -->
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" type="submit" block>
                    حفظ
                  </b-button>

                  <!-- Button: DOwnload -->

                  <!-- Button: Print -->
                  <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
          >
            Save
          </b-button> -->
                </b-col>
              </b-row>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onMounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length
} from "@validations";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    Logo
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle
  },
  data() {
    return {
      number: "",
      numberRange: "",
      numberRegx: "",

      required,
      min,
      integer,
      between,
      digits,
      length
    };
  },
  mixins: [heightTransition],
  watch: {
    // eslint-disable-next-line func-names
    "productData.items": function () {
      this.initTrHeight();
    }
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = "hidden";
      this.productData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)));
      if (this.productData.items.length % 2 !== 0 && this.productData.items.length > 1) {
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight);
          setTimeout(() => {
            this.$refs.form.style.overflow = null;
          }, 350);
        });
      }
    },
    removeItem(index) {
      this.productData.items.splice(index, 1);
      if (this.productData.items.length % 2 == 0 && this.productData.items.length > 1) {
        this.trTrimHeight(this.$refs.row[0].offsetHeight);
      }
    },
    initTrHeight() {
      this.trSetHeight(280);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    }
  },
  setup() {
    const mainProducts = ref([]);
    const refFormObserver = ref(null);

    const restData = {
      product_id: null,
      variation_id: null,
      // ? Set single Item in form for adding data
      // items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      items: [
        {
          from_qty: 0,
          to_qty: 0,
          price: 0,
          status: true
        }
      ]
    };
    const itemFormBlankItem = {
      from_qty: undefined,
      to_qty: undefined,
      price: undefined,
      status: true
    };

    const productData = ref({
      product_id: null,
      variation_id: null,
      items: [
        {
          from_qty: undefined,
          to_qty: undefined,
          price: undefined,
          status: true
        }
      ]
    });

    store.dispatch("myproducts/fetchMainProducts").then(response => {
      mainProducts.value = response.data;
    });

    onMounted(() => {
      //  productData.value = store.state.myproducts.product;
    });

    store
      .dispatch("myproducts/fetchProduct", {
        id: router.currentRoute.params.id,
        params: {
          is_edit: true
        }
      })
      .then(response => {
        productData.value = response.data;
      })
      .catch(error => {
        // if (error.response.status === 404) {
        //   //  productData.value = undefined;
        // }
      });
    // store.dispatch("app-product/fetchClients").then(response => {
    //   clients.value = response.data;
    // });

    const validationForm = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          onSubmit();
        }
      });
    };

    const onSubmit = () => {
      store.dispatch("myproducts/updateProduct", { id: router.currentRoute.params.id, params: productData.value }).then(res => {
        router.push({ name: "admin.products.index" });
      });
    };
    const updateItemForm = (index, val) => {
      // const { cost, qty, description } = val;
      // productData.value.items[index].cost = cost;
      // productData.value.items[index].qty = qty;
      // productData.value.items[index].description = description;
      // productData.value.items = [];
    };

    return {
      productData,
      mainProducts,
      updateItemForm,
      itemFormBlankItem,

      validationForm,

      refFormObserver
    };
  }
};
</script>

<style lang="scss">
@import "~@resources/scss/vue/libs/vue-select.scss";
@import "~@resources/scss/vue/libs/vue-flatpicker.scss";
.product-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@resources/scss/base/pages/app-invoice.scss";
@import "~@resources/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
